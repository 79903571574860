<template>
    <template v-if="dataObject.hasPagedData && dataObject.pagedData.enabled">
        <span class="d-flex align-items-center drag-ignore">
            <button class="btn btn-sm btn-link px-1" :disabled="dataObject.pagedData.isFirstPage"
                @click="dataObject.pagedData.goToPage(0)"
                :title="$t('Go to first page')">
                <i class="bi bi-chevron-bar-left"></i>
            </button>
            <button class="btn btn-sm btn-link px-1" :disabled="dataObject.pagedData.isFirstPage"
                @click="dataObject.pagedData.previous()"
                :title="$t('Go to previous page')">
                <i class="bi bi-chevron-left"></i>
            </button>
            <button class="btn btn-sm btn-link px-1" :disabled="dataObject.pagedData.isLastPage"
                @click="dataObject.pagedData.next()"
                :title="$t('Go to next page')">
                <i class="bi bi-chevron-right"></i>
            </button>
            <button class="btn btn-sm btn-link px-1" :disabled="dataObject.pagedData.isLastPage"
                @click="dataObject.pagedData.goToLastPage()"
                :title="$t('Go to last page')">
                <i class="bi bi-chevron-bar-right"></i>
            </button>

            <span style="white-space: pre;">{{dataObject.storage.data.length == 0 ? 0 : dataObject.pagedData.currentIndex + 1}}-{{lastPageItemIndex}} / </span>
            <div v-if="dataObject.state.isRowCountLoading" class="spinner-border spinner-border-sm ms-1 align-self-center" role="status">
                <span class="sr-only"></span>
            </div>
            <span v-else-if="dataObject.rowCount != null">{{ rowCount }}</span>
            <button v-else class="btn btn-sm btn-link p-0" :title="$t('Load row count')" @click="loadRowCounts">
                <i class="bi bi-question-circle"></i>
            </button>
            <ODropdown v-if="pageSizeDropdown">
                <template #default="{ target, open }">
                    <button :ref="target"  @click="open" class="btn btn-sm btn-link">
                        <i class="bi bi-list"></i>
                    </button>
                </template>
                <template #dropdown="scope">
                    <div class="dropdown-menu show" :ref="scope.container">
                        <div class="hstack px-4">
                            <span>{{ $t('Page size') }}</span>
                            <input :value="dataObject.pagedData.pageSize" class="ms-2 form-control form-control-sm text-center" 
                                @blur="e => updatePageSize(e)"
                                @keydown.enter="e => {updatePageSize(e); scope.close();}"
                                type="number" min="0" step="1" max="500" :title="$t('Set page size')"  style="width: 60px;">
                            <button v-if="dataObject.pagedData.pageSize !== dataObject.pagedData.initialPageSize" 
                                @click="() => dataObject.pagedData.pageSize = dataObject.pagedData.initialPageSize"
                                class="btn btn-sm btn-link" :title="$t('Reset to default')">
                                <i class="bi bi-arrow-counterclockwise"></i>
                            </button>
                        </div>
                    </div>
                </template>
            </ODropdown>

            <button v-if="pageSizeDropdown" class="btn btn-link btn-sm ps-0" @click="() => dataObject.load()" :disabled="dataObject.state.isLoading">
                <div v-if="dataObject.state.isLoading" class="spinner-border spinner-border-sm align-self-center" role="status">
                    <span class="sr-only"></span>
                </div>
                <i v-else class="bi bi-arrow-clockwise"></i>
            </button>
        </span>
        <!-- <div v-if="dataObject.state.isLoading" class="spinner-border spinner-border-sm ms-1 align-self-center" role="status"> -->
            <!-- <span class="sr-only"></span> -->
        <!-- </div> -->
        <!-- <b v-else class="align-self-center"> -->
            <!-- <span v-if="dataObject.current?.index !== undefined" :title="$t('Current Row')">{{$formatNumber(dataObject.current?.index+1, '1 234')}} / </span> -->
            <!-- <span :title="$t('Loaded Rows')">{{$formatNumber(dataObject.data.length, '1 234')}}</span> -->
        <!-- </b> -->
    </template>
</template>

<script setup lang="ts">
import type DataObject from 'o365.modules.DataObject.ts';

import ODropdown from 'o365.vue.components.DropDown.vue';
import logger from 'o365.modules.Logger.ts';
import { computed } from 'vue';

const props = defineProps<{
    dataObject: DataObject,
    pageSizeDropdown?: boolean
}>();

if (!(props.dataObject.hasPagedData && props.dataObject.pagedData.enabled)) {
    logger.warn(`${props.dataObject.id} does not have pagedData extension enabled, the paginator component will not render anything`);
}

const rowCount = computed(() => {
    if (props.dataObject.rowCount == null) {
        return props.dataObject.rowCount;
    } else if (props.dataObject.batchDataEnabled) {
        return props.dataObject.rowCount + props.dataObject.batchData.rowCount;
    } else {
        return props.dataObject.rowCount;
    }
})
const lastPageItemIndex = computed(() => {
    if (props.dataObject.batchDataEnabled) {
        return props.dataObject.pagedData.lastIndex + 1 + props.dataObject.batchData.rowCount;
    } else {
        return props.dataObject.pagedData.lastIndex + 1;
    }
})

function loadRowCounts() {
    props.dataObject.recordSource.loadRowCount();
}

function updatePageSize(e: Event) {
    const element = e.target as HTMLInputElement;
    const size = +(element.value || 0);
    props.dataObject.pagedData.pageSize = size < 0
        ? 0
        : size > 500
            ? 500
            : size;
}

</script>